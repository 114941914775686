import pickBy from 'lodash/pickBy'

const getAnalytics = () => {
  if (typeof window == 'undefined') {
    console.error('window undefined. could not get analytics.')
    return
  }
  return window.analytics
}

const isAnalyticsLoaded = () => {
  const analytics = getAnalytics()
  return Boolean(analytics)
}

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
const identify = (firebaseUser) => {
  if (!firebaseUser) {
    return
  }
  const analytics = getAnalytics()
  if (!analytics) {
    return
  }
  const {
    uid,
    displayName,
    email,
    isAnonymous,
  } = firebaseUser
  analytics.identify(uid, {
                            displayName,
                            email,
                            isAnonymous,
                          }
                    )
}

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#default-page-properties
/*
Segment adds several fields automatically
analytics.page(name, {
  title: 'Segment Pricing',
  url: 'https://segment.com/pricing',
  path: '/pricing',
  referrer: 'https://segment.com/warehouses'
});
*/
const trackPage = (name, properties, options, callback) => {
  const analytics = getAnalytics()
  if (!analytics) {
    return
  }
  analytics.page(name, properties, options, callback)
}

const trackEvent = (name, properties, options, callback) => {
  const analytics = getAnalytics()
  if (!analytics) {
    // console.error(`Could not track event: ${name}. No analytics.`)
    return
  }
  analytics.track(name, properties, options, callback)
}

/*
  returns an object with
  - word and character counts of each profile section
  - total word and character count for all sections combined
  - list of sections that have some content
*/
const summarizeProfile = (profile) => {
  const {
    personalDetails,
    professionalSummary,
    websitesAndSocial,
    employmentHistory,
    education,
    skills,
  } = profile

  const countWords = (string) => {
    const typeToString = Object.prototype.toString.call(string)
    switch (typeToString) {
      case '[object Date]':
        return 3
      case '[object Boolean]':
        return 1
      case '[object Number]':
        return 1
      case '[object String]': {
        if (string.length == 0) {
          return 0
        }
        return string.split(' ').length
      }
      default:
        console.error('summarizeProfile.countWords received unknown type: ', typeToString)
        return 0
    }
    return 0
  }

  const countChars = (string) => {
    const typeToString = Object.prototype.toString.call(string)
    switch (typeToString) {
      case '[object Date]':
        return 6
      case '[object Boolean]':
        return 4
      case '[object Number]':
        return String(string).length
      case '[object String]': {
        return string.length
      }
      default:
        console.error('summarizeProfile.countChars received unknown type: ', typeToString)
        return 0
    }
    return 0
  }

  const sections = {}

  const personalDetailsCounts = {
    wordCount: 0,
    charCount: 0,
  }
  Object.values(personalDetails).forEach(field => {
    personalDetailsCounts.wordCount += countWords(field)
    personalDetailsCounts.charCount += countChars(field)
  })
  sections.personalDetails = personalDetailsCounts

  sections.professionalSummary = {
    wordCount: countWords(professionalSummary),
    charCount: countChars(professionalSummary),
  }

  // for education, employment, skills, websites and social
  // which are arrays of objects
  // iterate through each object
  // take values for each key except uuid
  // add up word and char counts
  const keysToExclude = ['uuid']
  const resultsForArrayOfObjectsField = (arrayOfObjects) => {
    const results = {
      wordCount: 0,
      charCount: 0.
    }
    arrayOfObjects.forEach(object => {
      Object.keys(object).forEach(key => {
        if (!keysToExclude.includes(key)) {
          results.wordCount += countWords(object[key])
          results.charCount += countChars(object[key])
        }
      })
    })
    return results
  }

  sections.websitesAndSocial = resultsForArrayOfObjectsField(websitesAndSocial)
  sections.employmentHistory = resultsForArrayOfObjectsField(employmentHistory)
  sections.education = resultsForArrayOfObjectsField(education)
  sections.skills = resultsForArrayOfObjectsField(skills)

  let charCount = 0
  let wordCount = 0
  const sectionsWithContent = []
  Object.keys(sections).forEach(sectionKey => {
    const section = sections[sectionKey]
    charCount += section.charCount
    wordCount += section.wordCount
    if (section.charCount > 0) {
      sectionsWithContent.push(sectionKey)
    }
  })

  const results = {
    sections,
    charCount,
    wordCount,
    sectionsWithContent,
  }
  return results
}

// pass in `location`` that is prop on every page
// pick up any desired properties from url params
// filter out any with undefined values
// returns object that can be passed into analytics properties

const getPropertiesFromLocation = (location) => {
  const searchParams = new URLSearchParams(location.search)
  const referring_page = searchParams.get('referring_page')
  const referring_cta = searchParams.get('referring_cta')
  const properties = {
    referring_page,
    referring_cta,
  }

  const filteredProperties = pickBy(properties, o => o!== undefined)
  return filteredProperties
}

export { isAnalyticsLoaded, identify, trackPage, trackEvent, summarizeProfile, getPropertiesFromLocation}
